import { IPagination } from "../../../types/api";
import { IAuthState } from "../../auth/types";
import { IEnvironmentClientResponse } from "../../environment-clients/types";

export interface ITenant {
  name: string;
  id: ITenantId;
  isUsingSourceGroups: boolean;
  isUsingSchemasBulkDeployment: boolean;
  javascriptSchemaFormatConfig: ITenantJavascriptConfig;
  plan: ITenantPlan;
  routesConfig?: IRoutesConfig;
  trashedAt?: Date;
}

export type TenantConfig = {
  isUsingSourceGroups: boolean;
  isUsingSchemasBulkDeployment: boolean;
  javascriptSchemaFormatConfig: ITenantJavascriptConfig;
  plan: ITenantPlan;
  routesConfig?: IRoutesConfig;
};

export interface ITenantResponse {
  name: string;
  id: string;
  trashedAt?: string;
  config: TenantConfig;
}

export interface ITenantJavascriptConfig {
  enabled: boolean;
}

export interface ITenantId {
  idValue: string;
  tenantGuid: string;
}

export interface ITenantCreate {
  tenant: ITenantPayload;
}

export interface IDatasetsImportPayload {
  name: string;
}

export interface ITenantPlan {
  id: string;
  deliveryApiRequestCountLimit: number;
  sourceEntitiesByteLimit: number;
  sourceEntitiesCountLimit: number;
}

export interface ITenantProperties {
  id: string;
  name: string;
  avoidRefresh: boolean;
  isUsingSourceGroups: boolean;
  isUsingSchemasBulkDeployment: boolean;
  javascriptSchemaFormatConfig: ITenantJavascriptConfig;
  plan?: ITenantPlan;
  routesConfig?: IRoutesConfig;
}

export interface IRoutesConfig {
  useFlatRoutes: "Disabled" | "Enabled" | "Migrating";
  comparisonStrategy: "LatestUpdatedAt" | "WeightThenUpdatedAt";
}

export interface ITenantInviteId {
  idValue: string;
  inviteGuid: string;
}
export interface ITenantPayload {
  name: string;
}

export interface ITenantAdminAllTenants {
  name: string;
  id: string;
  currentTenant?: never;
  isUsingSourceGroups: boolean;
  isUsingSchemasBulkDeployment: boolean;
}

export interface IImportDemoDataPayload {
  demodata: boolean;
}

export interface ITenantAdminPayload extends ITenantPayload {
  maxProcessingTimeInSeconds?: number;
}

export interface ITenantAdminAllTenantGetPayload {
  next: boolean;
  term?: string;
}

export interface ITenantGetAdminAllTenantsParams extends IPagination {
  term?: string;
}

export interface ITenantCreateInvitePayload {
  inviteeEmail: string;
  message: string;
}

export interface ITenantGetInvitesParams extends IPagination {
  inviteeEmailTerm?: string;
  state?: InviteState;
}

export interface ITenantGetUsersParams extends IPagination {
  term?: string;
}

export enum InviteState {
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  DECLINED = "Declined",
  EXPIRED = "Expired",
  CANCELLED = "Cancelled"
}

export interface ITenantInvitee {
  email: string;
  userId: string;
}

export interface ITenantInviter extends ITenantInvitee {
  firstname: string;
  lastname: string;
}

export interface ITenantInvite {
  id: ITenantInviteId;
  invitee: ITenantInvitee;
  inviter: ITenantInviter;
  message: string;
  tenantRoles: string[];
  state: InviteState;
  createdAt: string;
  expiresAt: string;
  acceptedAt: string;
  declinedAt: string;
  cancelledAt: string;
  tenant: ITenant;
}

// export interface ITentantInvitesGetPayload {
//   next: boolean;
// }

// export interface ITentantUsersGetPayload {
//   next: boolean;
// }

export interface ITenantUser {
  id: {
    idValue: string;
    userGuid: string;
  };
  firstName: string;
  lastName: string;
  identityId: string;
  identityIssuer: string;
  emails: string[];
  roles: string[];
  tenants: { [key: string]: string[] };
}

export interface ITenantUserTable {
  id: string;
  userGuid: string;
  userName: string;
  userRole: string;
  email: string;
}

export enum TenantUsersTableModalType {
  REMOVE_FROM_TENANT = "Remove from tenant"
}

export interface IInviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  addExistingUser?: boolean;
}

export interface ISentInvitation {
  id: string;
  email: string;
  status: string;
  dateSent: string;
  dateExpires: string;
}

export enum TenantSentInvitationsTableActionType {
  REVOKE_INVITATION = "Revoke invitation",
  RESEND_EMAIL = "Resend email"
}

export interface IRouteDeliveryResponse {
  url: string;
  handle: string;
  environmentClients: IEnvironmentClientResponse[];
}

export interface ITenantStore {
  availableTenants: ITenant[];
  preSelectedTenant: ITenant | null;
  activeTenant: ITenantProperties;
  isImpersonating: boolean;
  setActiveTenant: (
    user: IAuthState,
    tenant: ITenant,
    avoidRefresh: boolean
  ) => void;
  setAvailableTenants: (tenants: ITenant[]) => void;
  addTenant: (tenant: ITenant) => void;
}
