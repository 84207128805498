import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { useAuthStore } from "../../auth/store";
import { useTenantStore } from "../store";
import { ITenant } from "../types";

const useTenantSwitcher = () => {
  const userState = useAuthStore();
  const { activeTenant, setActiveTenant, availableTenants } = useTenantStore(
    useShallow(({ activeTenant, setActiveTenant, availableTenants }) => ({
      activeTenant,
      setActiveTenant,
      availableTenants
    }))
  );
  const mixpanel = useMixPanel();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return {
    switch: (
      tenant: ITenant,
      { redirectToHome }: { redirectToHome: boolean } = {
        redirectToHome: true
      }
    ) => {
      mixpanel.track("tenantSwitch", {
        from: activeTenant,
        to: tenant
      });

      setActiveTenant(userState, tenant, true);
      queryClient.clear();
      if (redirectToHome) {
        return navigate("/");
      }
    },
    switchToPreviousAvailableTenant() {
      const tenantId = localStorage.getItem("enterspeed-previous-tenant-id");
      const tenant =
        availableTenants.find((t) => t.id.idValue === tenantId) ??
        availableTenants.reverse()[0]; // reversing list to switch back to latest created tenant and not oldest tenant and hopefully avoiding deprecation blocker

      setActiveTenant(userState, tenant ?? availableTenants[0], true);
      queryClient.clear();
      return navigate("/");
    }
  };
};

export default useTenantSwitcher;
